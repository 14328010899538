import * as FileSaver from 'file-saver'
import moment from 'moment'
const isNotNullUndefinedOrEmpty = (prop) => {
  return !!(prop !== undefined && prop !== null && prop !== '')
}

const isNotUndefinedAndNull = (value) => {
  return !!(value !== undefined && value !== null)
}

const convertToCamelCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const IsTrue = (value) => {
  return !!(value !== undefined && value === true)
}

const isPercentageNotMoreThenValid = (data) => {
  let count = 0
  if (isNotUndefinedAndNull(data) && data.length > 0) {
    data.forEach((value) => {
      count += isNaN(value.percentage) ? 0 : parseInt(value.percentage)
    })
  }

  return !(count > 100)
}

const IsDecimal = (value) => {
  return isNotUndefinedAndNull(value) && value.toString().includes('.')
}

const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
function getDate(date) {
  if (date) return moment(date).format('DD/MM/YYYY')
  else return `N/A`
}

const formatDateWithAMPM = (dateString) => {
  if (dateString) return moment(dateString).format('DD/MM/YYYY : hh:mm')
  else return `N/A`
}

const getFormattedDate = (unformatedDate) => {
  const date = new Date(unformatedDate)
  const year = date.getFullYear()
  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month
  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day
  return day + '-' + month + '-' + year
}

const getFormattedDayMonth = (unformatedDate) => {
  const weekday = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = new Date(unformatedDate)

  const month = date.getMonth()

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return weekday[month] + ' ' + day
}

function getFormatedAMPMTime(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const getFromattedDateTime = (unformatedDateTime) => {
  const dateObj = new Date(unformatedDateTime)
  const date = getFormattedDate(dateObj)
  const time = getFormatedAMPMTime(dateObj)
  return date + ' ' + time
}

const getChatMessageDateTime = (unformatedDateTime) => {
  const dateObj = new Date(unformatedDateTime)
  const timeObj = getFormatedAMPMTime(dateObj)
  const date = getFormattedDayMonth(dateObj)
  return timeObj + ' | ' + date
}

const getCompleteUrl = (resourceUrl) => {
  return resourceUrl
}

const capitalizeFirstLetter = (data) => {
  if (isNotUndefinedAndNull(data)) {
    const input = data?.toLowerCase()
    return input.charAt(0).toUpperCase() + input.slice(1)
  } else return data
}

const concatStringIfMore = (string, maxSize) => {
  return string?.length > maxSize ? `${string.substring(0, maxSize - 3)}...` : string
}

const getExtension = (string) => {
  const lastDot = string.lastIndexOf('.')
  const subString = string.substring(lastDot + 1)
  const concatString = concatStringIfMore(string, 8)
  return concatString + '.' + subString
}

const getISTDateTime = () => {
  const currentTime = new Date()
  const currentOffset = currentTime.getTimezoneOffset()
  const ISTOffset = 330 // IST offset UTC +5:30
  return new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)
}

const isArray = (obj) => {
  return isNotUndefinedAndNull(obj) && obj.length > 0
}

const isDateValid = (date) => {
  return isNotUndefinedAndNull(date) && date !== '0001-01-01T00:00:00' && date !== '1970-01-01T00:00:00Z'
}

const getFullName = (fName, lName) => {
  return isNotNullUndefinedOrEmpty(fName) && isNotNullUndefinedOrEmpty(lName)
    ? fName + ' ' + lName
    : isNotNullUndefinedOrEmpty(fName)
    ? fName
    : isNotNullUndefinedOrEmpty(lName)
    ? lName
    : ''
}
const FilterUniqueIds = (data, idToRemove) => {
  return data.filter((item) => item.companyId !== idToRemove)
}

const isAuthorized = (userRole, allowedRole) => {
  return userRole >= allowedRole
}

const exportToCSV = (csvData, fileName, tableHead) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const wb = XLSX.utils.book_new()
  const toUpperCaseKeys = (obj) => {
    const newObj = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key.toUpperCase()] = obj[key]
      }
    }
    return newObj
  }
  if (fileName == 'products') {
    csvData?.forEach((element) => {
      element.images = element.images.join(', ')
    })
    csvData?.forEach((element) => {
      element.allColors = element.allColors.join(', ')
    })

    const testdata = csvData.map((obj) => {
      const { measurements, ...rest } = obj
      const { properties, ...obj2 } = obj
      const { shipping, ...obj3 } = obj
      const { productDesign, ...obj4 } = obj
      measurements.map((el) => {
        for (let [key, value] of Object.entries(el)) {
          rest[key] = value
        }
      })
      properties.map((el) => {
        for (let [key, value] of Object.entries(el)) {
          obj2[key] = value
        }
        obj2.content = obj2?.content.join(', ')
      })
      shipping.map((el) => {
        for (let [key, value] of Object.entries(el)) {
          obj3[key] = value
        }
      })
      productDesign.map((el) => {
        for (let [key, value] of Object.entries(el)) {
          obj4[key] = value
        }
      })
      return toUpperCaseKeys({ ...rest, ...obj2, ...obj3, ...obj4 })
    })

    const ws = XLSX.utils.json_to_sheet(testdata)
    const colName = [
      'A1',
      'B1',
      'C1',
      'D1',
      'E1',
      'F1',
      'G1',
      'H1',
      'I1',
      'J1',
      'K1',
      'L1',
      'M1',
      'N1',
      'O1',
      'P1',
      'Q1',
      'R1',
      'S1',
      'T1',
      'U1',
      'V1',
      'W1',
      'X1',
      'Y1',
      'Z1',
      'AA1',
      'AB1',
      'AC1',
      'AD1',
      'AE1',
      'AF1',
      'AG1',
      'AH1',
      'AI1',
      'AJ1',
      'AK1',
      'AL1',
      'AM1',
      'AN1',
      'AO1',
      'AP1',
      'AQ1',
      'AR1',
      'AS1'
    ]
    for (const itm of colName) {
      ws[itm].s = {
        fill: {
          fgColor: { rgb: '1eff1e' }
        },
        font: {
          color: { rgb: '000000' },
          bold: true
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, 'productsData')
  } else if (fileName === 'flamm') {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const colName = ['A1', 'B1', 'C1', 'D1']
    for (const itm of colName) {
      ws[itm].s = {
        fill: {
          fgColor: { rgb: '1eff1e' }
        },
        font: {
          color: { rgb: '000000' },
          bold: true
        }
      }
    }
    XLSX.utils.book_append_sheet(wb, ws, 'flammData')
  } else if (fileName === 'oekotex') {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const colName = ['A1', 'B1', 'C1']
    for (const itm of colName) {
      ws[itm].s = {
        fill: {
          fgColor: { rgb: '1eff1e' }
        },
        font: {
          color: { rgb: '000000' },
          bold: true
        }
      }
    }
    XLSX.utils.book_append_sheet(wb, ws, 'oekotexData')
  } else if (fileName != 'OverviewData') {
    const ws = XLSX.utils.json_to_sheet(csvData)
    XLSX.utils.sheet_add_aoa(ws, [['Month']], { origin: 'A1' })
    XLSX.utils.sheet_add_aoa(ws, [[tableHead]], { origin: 'B1' })
    XLSX.utils.book_append_sheet(wb, ws, 'data')
  } else {
    const tableHeads = [
      'Visited Catalogues',
      'Unique Catalogues',
      'Downloaded Catalogues',
      'New Users',
      'Queried Catalogues'
    ]
    const sheetNames = ['Visited', 'Unique', 'Downloaded', 'new users', 'Queried']
    csvData.forEach((field, idx) => {
      const ws = XLSX.utils.json_to_sheet(field)
      XLSX.utils.sheet_add_aoa(ws, [['Month']], { origin: 'A1' })
      XLSX.utils.sheet_add_aoa(ws, [[tableHeads[idx]]], { origin: 'B1' })
      XLSX.utils.book_append_sheet(wb, ws, sheetNames[idx])
    })
  }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

const sampleToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet charset=UTF-8'
  const fileExtension = '.xlsx'
  const wb = XLSX.utils.book_new()

  if (fileName === 'products') {
    const csvHeader = csvData && csvData.length > 0 ? Object.keys(csvData[0]) : []
    const csvMeasurements =
      csvData && csvData[0]?.measurements && csvData[0].measurements.length > 0
        ? Object.keys(csvData[0].measurements[0])
        : []
    const csvProperties =
      csvData && csvData[0]?.properties && csvData[0].properties.length > 0 ? Object.keys(csvData[0].properties[0]) : []
    const csvShipping =
      csvData && csvData[0]?.shipping && csvData[0].shipping.length > 0 ? Object.keys(csvData[0].shipping[0]) : []
    const csvProductDesign =
      csvData && csvData[0]?.productDesign && csvData[0].productDesign.length > 0
        ? Object.keys(csvData[0].productDesign[0])
        : []

    const sampleCsvFile = [...csvHeader, ...csvMeasurements, ...csvProperties, ...csvShipping, ...csvProductDesign]

    const ws = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, [sampleCsvFile])

    const colName = [
      'A1',
      'B1',
      'C1',
      'D1',
      'E1',
      'F1',
      'G1',
      'H1',
      'I1',
      'J1',
      'K1',
      'L1',
      'M1',
      'N1',
      'O1',
      'P1',
      'Q1',
      'R1',
      'S1',
      'T1',
      'U1',
      'V1',
      'W1',
      'X1',
      'Y1',
      'Z1',
      'AA1',
      'AB1',
      'AC1',
      'AD1',
      'AE1',
      'AF1',
      'AG1',
      'AH1',
      'AI1',
      'AJ1',
      'AK1',
      'AL1',
      'AM1',
      'AN1',
      'AO1',
      'AP1',
      'AQ1',
      'AR1',
      'AS1',
      'AT1',
      'AU1',
      'AV1',
      'AW1',
      'AX1',
      'AY1'
    ]
    for (const itm of colName) {
      ws[itm].s = {
        fill: {
          fgColor: { rgb: '1eff1e' }
        },
        font: {
          color: { rgb: '000000' },
          bold: true
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, 'productsData')
  }

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

const exportJsonToCsv = async (csvData, fileName) => {
  try {
    if (!Array.isArray(csvData) || csvData.length === 0) {
      throw new Error('No data to export')
    }
    let maxKeysObject = csvData.reduce(
      (maxObj, currentObj) => (Object.keys(currentObj).length > Object.keys(maxObj).length ? currentObj : maxObj),
      {}
    )
    const headers = Object.keys(maxKeysObject)
    const rows = [headers]
    csvData.forEach((res) => {
      const row = headers.map((header) => (res[header] !== undefined ? res[header] : ''))
      rows.push(row)
    })
    await exportJsonToCsvCustomRows(rows, fileName)
  } catch (error) {
    console.error('Error exporting data', error)
  }
}
const exportJsonToCsvCustomRows = async (jsonData, fileName, type = 'download') => {
  try {
    const worksheet = XLSX.utils.aoa_to_sheet(jsonData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })
    const blob = new Blob([s2ab(xlsxFile)], { type: 'application/octet-stream' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `${fileName}.xlsx`
    if (type === 'base64') {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1]
          resolve(base64String)
        }
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(blob)
      })
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Error exporting data', error)
  }
}
function s2ab(s) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}

const addText = (slide, id) => {
  slide.addText(
    [
      {
        text: 'Visit e-Catalogue',
        options: {
          hyperlink: {
            url: `${window.location.origin}/ecatalogue/${id}`,
            tooltip: 'Visit Entire Catalog online @InnoSales'
          }
        }
      }
    ],
    {
      x: 0,
      y: 7.0,
      w: 6,
      h: 0.5,
      placeholder: 'cat-link',
      margin: 10,
      fontSize: 14,
      align: 'left',
      color: '6495ED'
    }
  )
}

const addProductImage = (slide, image, a, b, c, d) => {
  slide.addImage({ path: image, x: a, y: b, w: c, h: d })
}

const addProductId = (slide, id, a, b, c) => {
  slide.addText(`Product Id : ${id}`, {
    x: a,
    y: b,
    w: c,
    margin: 10,
    fontSize: 16,
    align: 'left',
    color: '000000'
  })
}

const pptProductTable = (product, slide, a, b, c, d) => {
  const arrTabRows = []
  for (const [key, value] of Object.entries(product)) {
    if (isNotNullUndefinedOrEmpty(value) && value !== 0) {
      switch (key) {
        case 'code':
          arrTabRows.push([
            {
              text: 'Product Code',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'name':
          arrTabRows.push([
            {
              text: 'Name',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'construction':
          arrTabRows.push([
            {
              text: 'Construction',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'subCategory':
          arrTabRows.push([
            {
              text: 'Sub Category',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'productionLeadTime':
          arrTabRows.push([
            {
              text: 'Product Leadtime (days)',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            { text: value, options: { valign: 'top', align: 'left', fontFace: 'Courier' } }
          ])
          break
        case 'moq':
          arrTabRows.push([
            {
              text: 'MOQ',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'dimention':
          arrTabRows.push([
            {
              text: 'Dimention',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'weight':
          arrTabRows.push([
            {
              text: 'Weight',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'cost':
          arrTabRows.push([
            {
              text: 'FOB Cost',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'materials':
          arrTabRows.push([
            {
              text: 'Material',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
      }
    }
  }
  slide.addTable(arrTabRows, {
    x: a,
    y: b,
    w: c,
    h: d,
    colW: [1.5, 1.5, 6],
    fill: { color: 'F7F7F7' },
    fontSize: 14,
    color: '363636',
    border: { pt: '1', color: 'BBCCDD' }
  })
}

const isObjectEmpty = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object
}

const checkValues = (type, value) => {
  if (type === 'cost') {
    if (
      isNotNullUndefinedOrEmpty(value?.details) &&
      isNotNullUndefinedOrEmpty(value?.details[0]) &&
      value.details[0].unitOfCurrency &&
      value.details[0].unitPerCost &&
      value.details[0].cost
    ) {
      return value.details[0].cost + ' ' + value.details[0].unitOfCurrency + ' ' + value.details[0].unitPerCost
    } else if (isNotNullUndefinedOrEmpty(value?.cost)) {
      return value?.cost
    } else {
      return ''
    }
  }
  if (type === 'dimention') {
    if (isNotNullUndefinedOrEmpty(value) && isNotNullUndefinedOrEmpty(value[0]) && value[0]?.unitOfShape) {
      if (value[0].shape === 'Rectangle' && value[0]?.length !== 0) {
        return value[0].length + 'x' + value[0].breadth + ' ' + value[0]?.unitOfShape
      } else if (value[0].shape === 'Square' && value[0]?.length !== 0) {
        return value[0].length + ' ' + value[0]?.unitOfShape
      } else if (value[0].shape === 'Round' && value[0]?.diameter !== 0) {
        return value[0].diameter + ' ' + value[0]?.unitOfShape
      } else if (value[0].shape === 'Custom' && value[0]?.custom !== 0) {
        return value[0].custom + ' ' + value[0]?.unitOfShape
      }
    } else if (isNotNullUndefinedOrEmpty(value) && !isNotNullUndefinedOrEmpty(value[0])) {
      return value
    } else {
      return ''
    }
  }
  if (type === 'Weight') {
    if (
      isNotNullUndefinedOrEmpty(value?.details) &&
      isNotNullUndefinedOrEmpty(value?.details[0]) &&
      value.details[0].weight &&
      value.details[0].unitOfWeight
    ) {
      return value.details[0].weight + ' ' + value.details[0].unitOfWeight
    } else if (isNotNullUndefinedOrEmpty(value?.weight)) {
      return value?.weight
    } else {
      return ''
    }
  }
}

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
function stringToBoolean(value) {
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'string') {
    return value.trim().toLowerCase() === 'true'
  }
  return false
}

async function downloadPDF(base64String, fileName = `${fileName}.pfd`) {
  let binaryString = atob(base64String)
  let blob = new Blob(
    [
      new Uint8Array(
        Array.prototype.map.call(binaryString, function (c) {
          return c.charCodeAt(0)
        })
      )
    ],
    { type: 'application/pdf' }
  )
  let url = URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.style = 'display: none'
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}
const readExcel = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.readAsArrayBuffer(file)
    fileReader.onload = (e) => {
      try {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { defval: '' })
        // Convert Excel serial numbers to dates
        const convertedData = data.map((row) => {
          Object.keys(row).forEach((key) => {
            if (key === 'DATE' && isExcelDate(row[key])) {
              row[key] = convertExcelDate(row[key])
            }
          })
          return row
        })
        resolve(convertedData)
      } catch (error) {
        reject(error)
      }
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

// Function to read the Excel file and fetch images for a given SKU
const readExcelAndFetchImages = (file, sku) => {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    fileReader.onload = (e) => {
      const bufferArray = e.target.result
      const wb = XLSX.read(bufferArray, { type: 'buffer' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws, { defval: '' })
      const matchingRows = data.filter((row) => row['SKU'] === sku)
      if (matchingRows.length > 0) {
        const images = matchingRows.map((row) => ({
          sku: row['SKU'],
          shape: row['Shape'],
          design_id: row['Design Id'],
          flat_image: row['Flat Image'],
          front: row['Front'],
          texture: row['Texture'],
          roomscene1: row['Roomscene 1'] || '',
          roomscene2: row['Roomscene 2'] || '',
          roomscene3: row['Roomscene 3'] || '',
          roomscene4: row['Roomscene 4'] || '',
          styleshot: row['Roomscene 5'] || '',
          video: row['Video'] || '',
          fold_image: row['Fold'] || '',
          corner: row['Corner'] || '',
          pile: row['Pile'] || '',
          swatch: row['Swatch'] || '',
          styleshot: row['Styleshot'] || '',
          bat_set_one: row['BatSet 1'] || '',
          bat_set_two: row['BatSet 2'] || ''
        }))
        resolve(images)
      } else {
        reject(`No Images found for SKU: ${sku} either your file is wrong or SKU is missing in Excel`)
      }
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
  return promise
}

// Function to check if a number could be an Excel date serial number
const isExcelDate = (num) => {
  return num > 0 && num < 2958465 // Reasonable range for dates
}

// Function to convert Excel date serial number to a JavaScript date
const convertExcelDate = (excelDate) => {
  const date = new Date((excelDate - (25567 + 2)) * 86400 * 1000) // Excel serial number to JS Date
  return date.toISOString().split('T')[0] // Returns date in YYYY-MM-DD format
}

// Funtion to Sort An array of object based on a particular key
const sortAlphabeticOrder = (data, key) => {
  if (Array.isArray(data) && data.length > 0) {
    return data.sort((first, second) => {
      if (first[key] < second[key]) {
        return -1
      }
      if (first[key] > second[key]) {
        return 1
      }
      return 0
    })
  }
  return data
}

function camelCaseToTitleCase(camelCaseStr) {
  return camelCaseStr?.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}
const currencyFormatter = (value) => {
  return `€${value}`
}
const percentFormatter = (value) => {
  return `${value}%`
}

const CalculateDate = async (selectedPeriod) => {
  const today = new Date()
  let startDate, endDate
  if (selectedPeriod === 'weekToDate') {
    endDate = today
    startDate = new Date(today)
    startDate.setDate(today.getDate() - 6)
  } else if (selectedPeriod === 'monthToDate') {
    endDate = today
    startDate = new Date(today.getFullYear(), today.getMonth(), 2)
  } else if (selectedPeriod === 'yearToDate') {
    endDate = today
    startDate = new Date(today.getFullYear(), 0, 2)
  } else {
    throw new Error('Invalid period selected')
  }
  return { start_date: startDate, end_date: endDate }
}

const CalculateDates = async (selectedPeriod) => {
  const today = new Date()
  let startDate, endDate

  switch (selectedPeriod) {
    case 'weekToDate':
      endDate = today
      startDate = new Date(today)
      startDate.setDate(today.getDate() - 6)
      break
    case 'monthToDate':
      endDate = today
      startDate = new Date(today.getFullYear(), today.getMonth(), 1)
      break
    case 'yearToDate':
      endDate = today
      startDate = new Date(today.getFullYear(), 0, 1, 5, 30, 0, 0) // Start of the year with time
      break
    case 'monthly':
      endDate = today
      startDate = new Date(today.getFullYear(), 0, 1, 5, 30, 0, 0) // Start of the year with time
      break
    case 'last8Weeks':
      endDate = today
      startDate = new Date(today)
      startDate.setDate(today.getDate() - 8 * 7) // 8 weeks ago
      break

    case 'lastMonth':
      endDate = new Date(today.getFullYear(), today.getMonth(), 0) // End of last month
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 2) // Start of last month
      break

    case 'quaterly':
      endDate = today
      startDate = new Date(today.getFullYear(), 0, 1, 5, 30, 0, 0) // Start of the year with time
      break
    default:
      throw new Error('Invalid period selected')
  }

  return { start_date: startDate, end_date: endDate }
}
const sortList = (Data, type, order) => {
  return [...Data].sort((key1, key2) => {
    let Key1Value = key1[type.toLowerCase()]
    let Key2Value = key2[type.toLowerCase()]
    if (type.toLowerCase() === 'acos') {
      Key1Value = (key1?.spend / key1?.revenue) * 100
      Key2Value = (key2?.spend / key2?.revenue) * 100
      if (!isFinite(Key1Value)) Key1Value = 0
      if (!isFinite(Key2Value)) Key2Value = 0
    } else if (type.toLowerCase() === 'sku') {
      const comparison = Key1Value?.localeCompare(Key2Value)
      return order === 'asc' ? comparison : -comparison
    }
    if (Key1Value === undefined || Key1Value === null) Key1Value = 0
    if (Key2Value === undefined || Key2Value === null) Key2Value = 0
    return order === 'asc' ? Key1Value - Key2Value : Key2Value - Key1Value
  })
}

const getCurrentMonthMinusOne = () => {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 1)
  const formattedDate = currentDate.toLocaleString('default', { month: 'long' })
  return `Last month (${formattedDate} ${currentDate.getFullYear()})`
}

const getCurrentQuarter = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1 // January is 0
  const currentQuarter = Math.ceil(currentMonth / 3)
  return `Current Quarter (Quarter ${currentQuarter} ${currentDate.getFullYear()})`
}

const calculatePercentageDifference = (current, previous) => {
  if (previous === 0) return current === 0 ? 0 : 100
  return ((current - previous) / previous) * 100
}

function manipulateString(str) {
  const lastChar = str.charAt(str.length - 1)
  const isLastCharDigit = !isNaN(lastChar)
  if (isLastCharDigit) {
    const baseString = str.replace(/\d+$/, '')
    const numberPart = str.match(/\d+$/)[0]
    const incrementedNumber = (parseInt(numberPart, 10) + 1).toString()
    const paddedNumber = incrementedNumber.padStart(numberPart.length, '0')
    return baseString + paddedNumber
  } else {
    return str.slice(0, -1) + String.fromCharCode(str.charCodeAt(str.length - 1) + 1)
  }
}

export {
  isNotNullUndefinedOrEmpty,
  convertToCamelCase,
  IsTrue,
  downloadPDF,
  isPercentageNotMoreThenValid,
  randomIntFromInterval,
  IsDecimal,
  getFromattedDateTime,
  getCompleteUrl,
  capitalizeFirstLetter,
  concatStringIfMore,
  getExtension,
  getChatMessageDateTime,
  isNotUndefinedAndNull,
  getISTDateTime,
  isArray,
  isDateValid,
  getFullName,
  FilterUniqueIds,
  isAuthorized,
  exportToCSV,
  sampleToCSV,
  addText,
  addProductImage,
  addProductId,
  pptProductTable,
  isObjectEmpty,
  checkValues,
  getDate,
  formatDateWithAMPM,
  isEmptyObject,
  stringToBoolean,
  readExcel,
  readExcelAndFetchImages,
  sortAlphabeticOrder,
  exportJsonToCsv,
  exportJsonToCsvCustomRows,
  camelCaseToTitleCase,
  currencyFormatter,
  percentFormatter,
  CalculateDate,
  CalculateDates,
  getCurrentMonthMinusOne,
  getCurrentQuarter,
  calculatePercentageDifference,
  sortList,
  manipulateString
}
