import React, { useContext, useState } from 'react'
import { Grid, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import PropTypes from 'prop-types'
import { getDate } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'

function PoHeader(props) {
  const purchaseOrderDetail = props.purchaseOrderDetail
  const { showToast } = useContext(UserContext)
  const [selectedDate, setSelectedDate] = useState(purchaseOrderDetail?.ready_date)
  const [tempDate, setTempDate] = useState(selectedDate)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handleDateChange = (event) => {
    setTempDate(event.target.value)
  }

  const handleOpenDatePicker = () => setOpenDatePicker(true)
  const handleCloseDatePicker = () => setOpenDatePicker(false)
  const handleOpenConfirmDialog = () => {
    if (!tempDate) return showToast('Please select Ready Date', false)
    setOpenConfirmDialog(true)
  }
  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false)

  const handleSaveDate = async () => {
    setSelectedDate(tempDate)
    setOpenDatePicker(false)
    setOpenConfirmDialog(false)
    const ProductValues = {
      ...purchaseOrderDetail,
      ready_date: tempDate
    }
    await props.editPurchaseOrder(ProductValues)
  }

  return (
    <>
      <div className='px-3 pb-4'>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' className='mt-4 mb-3'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              {/* Supplier and PO Date section */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Supplier</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.supplierName?.split(' ')[0]}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Po Date</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{getDate(purchaseOrderDetail?.order_deadline || purchaseOrderDetail?.poDate)}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Ship To and PO Due Date section */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Ship To</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.shipTo || 'N/A'}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Po Due Date</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      {moment(purchaseOrderDetail?.order_deadline || purchaseOrderDetail?.poDate)
                        .add(1, 'months')
                        .format('DD/MM/YYYY')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* PO Number and Load Date section */}
            <Grid container>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Po Number</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.poNumber}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Load Date</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>Yet to be Confirmed</p>
                  </Grid>
                </Grid>
              </Grid>

              {/* PO Status and Ship Via section */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>PO Status</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p
                      className={
                        ['cancelled'].includes(purchaseOrderDetail?.status?.toLowerCase())
                          ? 'text-danger fw-bold'
                          : ['request for change'].includes(purchaseOrderDetail?.status?.toLowerCase())
                          ? 'text-warning fw-bold'
                          : 'text-success fw-bold'
                      }
                    >
                      {purchaseOrderDetail?.status}
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              {/* Ship Via and Ready Date section */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h5>Ship Via</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{purchaseOrderDetail?.shipVia || 'N/A'}</p>
                  </Grid>
                </Grid>
              </Grid>

              {/* Ready Date and Edit Button */}
              {props?.editPurchaseOrder ? (
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <h5>Ready Date</h5>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginBottom: '0px' }}>{getDate(purchaseOrderDetail?.ready_date)}</p>
                        <IconButton size='small' sx={{ ml: 1 }} onClick={handleOpenDatePicker}>
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Date Picker Dialog */}
      <Dialog
        open={openDatePicker}
        onClose={handleCloseDatePicker}
        PaperProps={{
          sx: {
            borderRadius: 4,
            padding: 2,
            minWidth: 350
          }
        }}
      >
        <DialogTitle>Select Ready Date</DialogTitle>
        <DialogContent sx={{ minWidth: 300 }}>
          <TextField
            label='Ready Date'
            type='date'
            value={moment(tempDate).format('YYYY-MM-DD')}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button onClick={handleCloseDatePicker} variant='outlined' size='small' sx={{ borderRadius: '8px' }}>
            Cancel
          </Button>
          <Button onClick={handleOpenConfirmDialog} variant='outlined' size='small' sx={{ borderRadius: '8px' }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        PaperProps={{
          sx: {
            borderRadius: 4,
            padding: 2,
            minWidth: 350
          }
        }}
      >
        <DialogTitle>Are you sure you want to update the ready date?</DialogTitle>
        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button onClick={handleCloseConfirmDialog} variant='outlined' size='small' sx={{ borderRadius: '8px' }}>
            No
          </Button>
          <Button onClick={handleSaveDate} variant='outlined' size='small' sx={{ borderRadius: '8px' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

PoHeader.propTypes = {
  purchaseOrderDetail: PropTypes.object,
  editPurchaseOrder: PropTypes.func
}

export default PoHeader
