import apiClient from 'api/apiClient'
import { endpoints } from 'api/endpoints'
import odooClient from 'api/odooClient'

const productService = {
  // Method to add a product
  addProduct: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_PRODUCT, input)
      return response
    } catch (error) {
      throw error
    }
  },

  createOddoProduct: async (input, type = process.env.REACT_APP_ODOO_API_KEY) => {
    try {
      const response = await odooClient.post(endpoints.ADD_ODDO_PRODUCT, input, { type })
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get product by ID
  getProductOnId: async (Sku) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCT_BY_SKU(Sku))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get all products on design
  getAllProductsOnDesign: async (companyId, design) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCTS_BY_DESIGN(companyId, design))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to search product
  searchProduct: async (companyId, searchText, productsPerPage, page) => {
    try {
      const response = await apiClient.get(endpoints.SEARCH_PRODUCT(companyId, searchText, productsPerPage, page))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to filter product
  filterProduct: async (companyId, design, vendorIds, colors, productsPerPage, page, skuFilter, productType) => {
    if (design?.length > 0 || vendorIds?.length > 0 || colors?.length > 0) {
      const response = await apiClient.get(
        endpoints.FILTER_PRODUCT(companyId, design, vendorIds, colors, productsPerPage, page)
      )
      return response
    } else {
      return await apiClient.get(
        `${process.env.REACT_APP_BASE_URL}/Product/company/${companyId}/${productsPerPage}/${page}/${skuFilter}/${productType}`
        // `https://localhost:7021/Product/forcompany/${companyId}/${productsPerPage}/${page}/${skuFilter}/${productType}`
      )
    }
  },

  // Method to get product by shop name
  getProductOnShopName: async (ShopName) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCT_BY_SHOP_NAME(ShopName))
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to delete a product
  deleteProduct: async (productId) => {
    try {
      const response = await apiClient.delete(endpoints.DELETE_PRODUCT(productId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to update a product
  updateProduct: async (id, input) => {
    try {
      const response = await apiClient.put(
        // `https://localhost:7021/Product/update/${id}`, input
        endpoints.UPDATE_PRODUCT(id),
        input
      )
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to update a product
  updateProductBySku: async (sku, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PRODUCT_BY_SKU(sku), input)
      return response
    } catch (error) {
      throw error
    }
  },

  // Method to get all company products
  getAllCompanyProduct: async (companyId, productsPerPage, page, skuFilter, productType) => {
    try {
      const response = await apiClient.get(
        // `https://localhost:7021/Product/forcompany/${companyId}/${productsPerPage}/${page}/${skuFilter}/${productType}`
        endpoints.GET_COMPANY_PRODUCTS(companyId, productsPerPage, page, skuFilter, productType)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get company product design
  getCompanyProductDesign: async (companyId, searchText, productsPerPage, page) => {
    try {
      let search = searchText === '' ? 'alldesigns' : searchText
      const response = await apiClient.get(
        endpoints.GET_COMPANY_PRODUCTS_BY_DESIGN(companyId, search, productsPerPage, page)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get all user products
  getAllUserProduct: async (userId, productsPerPage, page) => {
    try {
      const response = await apiClient.get(endpoints.GET_USER_PRODUCTS(userId, productsPerPage, page))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get all products on company
  getAllProductOnCompany: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCTS_BY_COMPANY(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get all products on company
  GetAllDiscontinuedProducts: async (filterType) => {
    try {
      const response = await apiClient.get(
        endpoints.GET_DISCONTINUED_PRODUCTS(filterType)
        // `https://localhost:7021/Product${endpoints.GET_DISCONTINUED_PRODUCTS(filterType)}`
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get a page of user products
  getAllUserProductPage: async (userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_USER_PRODUCTS_PAGE(userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get all products on vendor ID
  getAllProductOnVendorId: async (vendorId, productsPerPage, page, sku) => {
    try {
      const response = await apiClient.get(
        // `https://localhost:7021/Product/vendor/${vendorId}/${productsPerPage}/${page}?Sku=${sku ? sku : "All"}`
        endpoints.GET_PRODUCTS_BY_VENDOR(vendorId, productsPerPage, page, sku)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to add bulk products
  addBulkProduct: async (bulkProducts) => {
    try {
      const response = await apiClient.post(endpoints.ADD_BULK_PRODUCTS, bulkProducts)
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get metadata by ID
  getMetaDataOnId: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_METADATA(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to update metadata by ID
  updateMetaDataOnId: async (companyId, updatedMetadata) => {
    try {
      const response = await apiClient.put(endpoints.UPDATE_METADATA(companyId), updatedMetadata)
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get product by ID
  getProductById: async (id) => {
    try {
      const response = await apiClient.get(
        // `https://localhost:7021/Product/${id}`
        endpoints.GET_PRODUCT_BY_ID(id)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get product by name
  getProduct: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCT_BY_NAME(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get product status
  getProductStatus: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCT_STATUS(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to delete multiple products
  deleteMultipleProducts: async (ids) => {
    try {
      const response = await apiClient.delete(endpoints.DELETE_MULTIPLE_PRODUCTS(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get products data
  getProductsData: async (productId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCTS_DATA(productId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to update multiple products by IDs
  updateMultipleProductsOnIds: async (productIds, updatedProducts) => {
    try {
      const response = await apiClient.put(endpoints.UPDATE_MULTIPLE_PRODUCTS(productIds), updatedProducts)
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get product designs
  getProductDesigns: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCT_DESIGNS(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get orders data
  getOrdersData: async (filters, limit, offset) => {
    let params = {
      limit: limit,
      offset: offset,
      ...filters
    }
    try {
      const response = await odooClient.get(endpoints.GET_ORDERS_DATA, {
        params: params
      })
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get details of order
  getOrderDetails: async (orderId) => {
    try {
      const response = await odooClient.get(endpoints.GET_ORDER_DETAILS(orderId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to get stocks data
  getStocksData: async () => {
    try {
      const response = await odooClient.get(endpoints.GET_STOCKS_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  // Method to create orders data
  createOrdersData: async (orderData) => {
    try {
      const response = await odooClient.post(endpoints.CREATE_ORDERS_DATA, orderData)
      return response
    } catch (error) {
      throw error.response
    }
  }
}

export default productService
