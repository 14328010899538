import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useContext } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'
import { useQuery, useMutation } from 'react-query'
import { exportJsonToCsvCustomRows, getDate } from 'shared/util/helper'

export const useProjectsDetailsHooks = (props) => {
  const { getProjectOnProjectId } = purchaseOrderService
  const { socket } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [projectDetail, setProjectDetail] = useState(false)
  const param = useParams()
  const [isProLoading, setIsProLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [column, setColumn] = useState([])
  const [projectDelivery, getProjectDelivery] = useState([])
  const [tempData, setTempData] = useState([])
  let [values, setValues] = useState({})
  const contextObj = useContext(UserContext)
  const { showToast } = useContext(UserContext)

  const columns = [
    { id: 'poNumber', label: 'Po Number', minWidth: 130 },
    { id: 'supplier', label: 'Supplier', minWidth: 130 },
    { id: 'shipTo', label: 'Ship\u00a0To', minWidth: 100 },
    { id: 'shipVia', label: 'Ship\u00a0Via', minWidth: 100 },
    { id: 'poDate', label: 'Po Date ', minWidth: 100 },
    { id: 'poDueDate', label: 'Po Due\u00a0Date', minWidth: 100 }
  ]

  const breadCrumbPath = [
    { name: 'Projects', url: `/projects`, active: 'not-allowed' },
    { name: 'Project-Details', url: `/projectsdetails/${param.projectId}`, active: 'not-allowed' },
    { name: 'Project-History', url: `/project/history/${param.projectId}`, active: 'not-allowed' }
  ]

  function createData(poNumber, supplier, shipTo, shipVia, poDate, poDueDate) {
    return { poNumber, supplier, shipTo, shipVia, poDate, poDueDate }
  }
  async function createDataDelivery(delivery, date) {
    return { delivery, date }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const toggleDrawer = (e) => {
    e.preventDefault()
    setFilterDrawer(!filterDrawer)
  }

  const { mutate: fetchProductsOnIds } = useMutation(
    async (ids) => {
      try {
        const response = await purchaseOrderService.getPurchaseOrdersOnIds(ids)
        return response
      } catch (error) {
        console.error(error)
      }
    },
    {
      onSuccess: (response) => {
        if (response?.data?.length) {
          const arr = response?.data?.map((project) =>
            createData(
              project.poNumber,
              project.supplierName,
              project.shipTo,
              project.shipVia,
              getDate(project.poDate),
              getDate(project.poDueDate)
            )
          )
          setRows(arr)
        }
      },
      onError: (error) => {
        console.error('Error fetching products:', error)
      }
    }
  )

  const fetchDeliveries = async (projectId) => {
    const arr = []
    try {
      const response = await purchaseOrderService.getDeliveryByProjectId(projectId)
      await Promise.all(
        response.data.map(async (delivery) => {
          arr.push(await createDataDelivery(delivery.deliveryId, getDate(delivery.date)))
        })
      )
      return arr
    } catch (error) {
      throw error
    }
  }

  useQuery(['deliveries', param.projectId], () => fetchDeliveries(param.projectId), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setColumn(data)
    },
    onError: (error) => {
      console.log(error)
    },
    enabled: param.projectId ? true : false
  })

  const transformData = (data) => {
    return data.map((item) => {
      return {
        deliveryId: item.deliveryId,
        'PO Number': item.poNumber,
        SKU: item.sku,
        QTY: item.quantity,
        WH: item.warehouse,
        upc: item.upc,
        shipWeight: item.shipWeight,
        notes: item.notes
      }
    })
  }

  const getDelivery = async () => {
    try {
      const response = await purchaseOrderService.getSingleDeliveryId(column[0]?.delivery)
      const transformedData = transformData(response.data?.purchaseOrder)
      return transformedData
    } catch (error) {
      throw error
    }
  }

  useQuery(['delivery', column[0]?.delivery], () => getDelivery(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      getProjectDelivery(data)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const [isPoLoading, setIsPoLoading] = useState(false)
  const {} = useQuery(
    ['purchaseOrder', param.projectId],
    async () => {
      setIsPoLoading(true)
      const response = await getProjectOnProjectId(param.projectId)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (data) => {
        let productData = []
        setProjectDetail(data)
        setTempData(data)
        setValues(data)
        await getPoChats({ poId: data?._id, page: 0, limit: 50 })
        await data.purchaseOrder.map((project) => {
          productData.push(project.poId)
        })
        await fetchProductsOnIds(productData)
        setIsPoLoading(false)
      },
      onError: (error) => {
        console.log(error, 'error')
        setError(error?.data)
        setIsPoLoading(false)
      }
    }
  )

  const fetchProjectHistory = async (projectId) => {
    try {
      const response = await purchaseOrderService.getProjectHistory(projectId)
      return response?.data
    } catch (error) {
      console.log(error, 'error')
      throw error
    }
  }

  const { data: projectHistory, isLoading: isHistoryLoad } = useQuery(
    ['projectHistory', param.projectId],
    () => fetchProjectHistory(param.projectId),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error)
      },
      enabled: param.projectId ? true : false
    }
  )

  const updateProjectMutation = useMutation(
    async (values) => {
      const apiRes = await purchaseOrderService.updateProjectOnProjectId(values)
      return apiRes.data
    },
    {
      onSuccess: (value) => {
        setSuccess(true)
        showToast(`Project Stage Updated to ${value.projectStage}`, true)
      },
      onError: (error) => {
        showToast(error?.data?.error_description)
        setSuccess(false)
        setError(error)
      }
    }
  )
  const onStageChange = () => {
    try {
      const changes = {}
      Object.keys(values).forEach((key) => {
        if (key !== 'purchaseOrder' && tempData[key] !== values[key]) {
          changes[key] = {
            oldValue: tempData[key],
            newValue: values[key]
          }
        }
      })
      const finalPayload = {
        ...values,
        changes
      }

      updateProjectMutation.mutate(finalPayload)
    } catch (error) {
      console.error(error)
    }
  }

  const sendMessage = (message, type) => {
    if (message !== '') {
      socket.emit('Message', {
        userId: contextObj.userData.user.id,
        poId: projectDetail._id,
        userName: contextObj.userData.user.fName,
        message: message,
        poNumber: projectDetail.projectId,
        isRead: false,
        type: type,
        socketID: socket.id,
        chatType: 'Project'
      })
    }
  }

  const { mutate: getPoChats } = useMutation(
    async (params) => {
      const { poId, page, limit } = params
      await purchaseOrderService.getAllPoChats(poId, page, limit)
    },
    {
      onSuccess: (response) => {
        if (response?.data?.length) {
          setMessages(response.data[0])
        }
      },
      onError: (err) => {
        setMessages({ error: 'Unable to Load Convertations' })
        setError('Seems like our server is down')
        console.log(err)
      }
    }
  )

  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState(0)
  useEffect(() => {
    if (!!contextObj?.userData?.user?.id) {
      socket.on(projectDetail._id, (data) => {
        if (!isOpen) {
          setNewMessage(newMessage + 1)
        } else {
          if (data.userId !== contextObj.userData.user.id) {
            socket.emit('UpdateMessage', {
              poId: projectDetail._id,
              ids: [data._id]
            })
          }
          setNewMessage(0)
        }
        setMessages(data)
      })
    }
  }, [socket, messages])

  const { data: allPoData } = useQuery(
    ['POLIST', contextObj.userData.user.companyId],
    async () => {
      const apiRes = await purchaseOrderService.getPurchaseOrder(contextObj.userData.user.companyId)
      const data = await apiRes.data
      return data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {},
      onError: (error) => {
        console.error(error)
        throw error
      },
      enabled: param.projectId ? true : false
    }
  )

  const downloadProjectDeliveryAndOrders = async (projectDelivery, orders) => {
    const rows = []
    rows.push(['Project Delivery Details'])
    rows.push([])
    const allowedKeys = [
      'projectId',
      'startDate',
      'vesselName',
      'loadDate',
      'freightLine',
      'cutOffDate',
      'bookingNumber',
      'container',
      'departureDate',
      'departurePort',
      'arrivalDate',
      'arrivalPort',
      'deliveryDate',
      'arrivalWarehouse',
      'deliveryDate'
    ]
    const keysRow = []
    const valuesRow = []
    for (const [key, value] of Object.entries(projectDelivery)) {
      if (allowedKeys.includes(key)) {
        keysRow.push(key)
        valuesRow.push(value)
      }
    }
    rows.push(keysRow)
    rows.push(valuesRow)
    rows.push([])
    rows.push([])
    rows.push(['Delivery Id'])
    rows.push([])
    rows.push(['PO Number', 'deliveryId', 'SKU', 'QTY', 'Warehouse', 'Upc', 'Ship Weight', 'Notes'])
    await orders.forEach((order) => {
      rows.push([
        order['PO Number'],
        order.deliveryId,
        order.SKU,
        order.QTY,
        order.WH,
        order.upc,
        order.shipWeight || 'N/A',
        order?.notes
      ])
    })
    await exportJsonToCsvCustomRows(rows, 'Project_Delivery_Id')
  }

  return {
    columns,
    rows,
    projectHistory,
    page,
    rowsPerPage,
    isPoLoading,
    isHistoryLoad,
    projectDetail,
    setProjectDetail,
    values,
    error,
    success,
    setValues,
    handleChangePage,
    handleChangeRowsPerPage,
    toggleDrawer,
    createData,
    onStageChange,
    contextObj,
    newMessage,
    setNewMessage,
    isOpen,
    setIsOpen,
    sendMessage,
    messages,
    allPoData,
    isProLoading,
    setIsProLoading,
    breadCrumbPath,
    downloadProjectDeliveryAndOrders,
    getDelivery,
    column,
    projectDelivery
  }
}
