import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Grid } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import View from 'components/products/product/View'
import { GrView } from 'react-icons/gr'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import PropTypes from 'prop-types'

import Modal from 'components/uiElements/modal/Dialog'

import { isNotNullUndefinedOrEmpty, isAuthorized } from 'shared/util/helper'

import { UserContext } from 'context/AuthContext/UserContext'
import Images from 'data/Images'
import { UserRoles } from 'data/Enums'
import Constants from 'data/Constants'

import './Tile.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

const Tile = (props) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const contextObj = React.useContext(UserContext)

  const [isChecked, setIsChecked] = useState(props.checked?.indexOf(props.index) > -1)

  const handleClick = (value) => {
    if (props.productView === 0) {
      props.refetchProductData(value)
    } else {
      props.setDesignData(value)
    }
  }
  useEffect(() => {
    setIsChecked(props?.checked?.indexOf(props?.index) > -1)
  }, [props?.checked])
  return (
    <>
      <div
        className='card-tile'
        id='pro-cards'
        role='button'
        onMouseEnter={() => {
          props.setHoverImage(props.productIndex)
        }}
        onMouseLeave={() => props.setHoverImage(-1)}
      >
        <div className='content'>
          <div className={`front ${isChecked ? 'front-effect-checked' : ''}`}>
            {props.hoverImage === props.productIndex ? (
              <span>
                <img
                  src={
                    props.productView === 0
                      ? props?.item?.images.length > 1
                        ? props?.item?.images[1]
                        : props?.item?.images[0]
                      : props.item.thumbnailImage
                  }
                  alt='not found'
                  className='hover-image'
                  width='100%'
                  height='100%'
                />
              </span>
            ) : props.productView === 0 ? (
              <LazyLoadImage
                className='profile'
                width='100%'
                height='100%'
                src={isNotNullUndefinedOrEmpty(props.item.images[0]) ? props.item.images[0] : Images.ImageFiller}
                alt={props.item.code}
                effect='blur'
                placeholderSrc={Constants.ImageFiller}
                threshold={50}
              />
            ) : (
              <LazyLoadImage
                className='profile'
                width='100%'
                height='100%'
                src={
                  isNotNullUndefinedOrEmpty(props.item.thumbnailImage) ? props.item.thumbnailImage : Images.ImageFiller
                }
                alt={props.item.code}
                effect='blur'
                placeholderSrc={Constants.ImageFiller}
                threshold={50}
              />
            )}
          </div>
          {!props.checked.length > 0 && (
            <div>
              <p className='product-name'>{props.item.name}</p>
              <p className='product-code'>
                <strong>SKU: </strong>
                {props.item.sku}
              </p>
              {props.item.isNew && (
                <img className='tem-img' src={require('assets/svgs/innosales/new.svg').default} alt='' />
              )}
              &nbsp;
              {props.item.isExclusive && (
                <img className='tem-img' src={require('assets/svgs/innosales/exclusive.svg').default} alt='' />
              )}
              <br />
              {props.item.discription && <p className='des'>{props.item.discription}</p>}
              {props.hoverImage === props.productIndex ? (
                <Grid container direction='row' justifyContent='center' alignItems='center' className='social-icon'>
                  {props.productView === 0 && (
                    <>
                      {' '}
                      <Tooltip title='View Product'>
                        <Grid item xs={3} id='view-icon'>
                          <GrView
                            color='#6495ED'
                            className='card-tile-actions'
                            onClick={() => navigate(`/products/${props?.item?.id}/product-view`)}
                            size='1.2em'
                          />
                        </Grid>
                      </Tooltip>
                      {contextObj.userData.user.pomUserType === 1 &&
                        isAuthorized(contextObj.userData?.user?.role, UserRoles.Write) ? (
                        <Tooltip title='Edit Product'>
                          <Grid item xs={3} id='edit-icon'>
                            <AiOutlineEdit
                              size='1.2em'
                              className='card-tile-actions'
                              color='#ff9f80'
                              onClick={() => navigate(`/products/${props.item.id}/edit`)}
                            />
                          </Grid>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                      {contextObj.userData.user.pomUserType === 1 &&
                        isAuthorized(contextObj.userData?.user?.role, UserRoles.Admin) ? (
                        <Tooltip title='Delete Product'>
                          <Grid item xs={3} id='delete-icon'>
                            <AiOutlineDelete
                              data-testid='delete'
                              size='1.2em'
                              className='card-tile-actions'
                              color='#FF0000'
                              onClick={(e) => {
                                e.preventDefault()
                                props.deleteProduct(props.item.id)
                              }}
                            />
                          </Grid>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  <div className='more-info-div'>
                    <button
                      className='more-info-btn'
                      onClick={(e) => {
                        e.preventDefault()
                        setOpen(true)
                        handleClick(props.productView === 0 ? props.item.sku : props.item.design)
                      }}
                    >
                      <AiOutlineInfoCircle size='1rem' /> &nbsp; More Info
                    </button>
                  </div>
                </Grid>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className='text-center' id='below-card'>
        <span className='card-tile-content'>{props.productView === 0 ? props.item.sku : props.item.design}</span>
      </div>
      {props.productDetail && props.productDetail.length > 0 && (
        <Modal
          open={open}
          fullWidth
          maxWidth='md'
          content={
            <View
              productId={props.productDetail[0].id}
              isUserView
              product={props.productDetail[0]}
              deleteProduct={props.deleteProduct}
              needReview={props.needReview}
              approveBtnClick={props.approveBtnClick}
              index={props.index}
              design={props.design}
            />
          }
          handleClose={() => {
            setOpen(false)
            props.SetProductDetail()
          }}
        />
      )}
    </>
  )
}

Tile.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  deleteProduct: PropTypes.func,
  setChecked: PropTypes.func,
  getProduct: PropTypes.func,
  refetchProductData: PropTypes.func,
  checked: PropTypes.array,
  needReview: PropTypes.bool,
  approveBtnClick: PropTypes.func,
  ind: PropTypes.number,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  productDetail: PropTypes.array,
  SetProductDetail: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  productIndex: PropTypes.number,
  setHoverImage: PropTypes.func,
  hoverImage: PropTypes.number,
  design: PropTypes.array,
  productView: PropTypes.number,
  getDesign: PropTypes.func,
  setDesignData: PropTypes.func,
}

export default Tile
