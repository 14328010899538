import React, { Fragment } from 'react'
import { CCol, CRow, CFormLabel, CFormInput, CFormSelect, CForm } from '@coreui/react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

function ProjectHeader(props) {
  const ArrivalPort = ['London Gateway', 'FELIXSTOWE', 'ROTTERDAM', 'FOS', 'VALENCIA PORT', 'BARCELONA PORT']
  const VesselName = ['Vessel', 'Truck']
  const DeparturePort = ['Turkey', 'Mumbai', 'China']
  const { projectId } = useParams()
  const { arrivalWarehouse } = props

  return (
    <Fragment>
      <CForm className='row g-3 needs-validation' noValidate validated={props.validated} onSubmit={props.handleSubmit}>
        <CRow className='ms-2 mt-5'>
          <CCol>
            <CRow>
              <CCol sm={6} lg={6}>
                <CFormLabel htmlFor='id' className='col-sm-4 col-form-label'>
                  Project ID<span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormInput
                  type='text'
                  id='projectId'
                  placeholder='Enter Project'
                  autoFocus={true}
                  onChange={props.handleChange('projectId')}
                  value={props?.values?.projectId}
                  feedbackValid=''
                  feedbackInvalid='Please Enter Project Id.'
                  disabled={projectId ? true : false}
                  required
                />
                <CFormLabel htmlFor='Bnumber' className='col-sm-4 col-form-label'>
                  Booking Number <span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormInput
                  type='text'
                  id='BookingNumber'
                  placeholder='Enter Booking Number'
                  onChange={props.handleChange('BookingNumber')}
                  value={props?.values?.BookingNumber}
                  feedbackValid=''
                  feedbackInvalid='Please Enter Booking Number.'
                  required
                />
                <CFormLabel htmlFor='Bnumber' className='col-sm-4 col-form-label'>
                  Container <span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormInput
                  type='text'
                  id='Container'
                  placeholder='Enter Container'
                  onChange={props.handleChange('Container')}
                  value={props?.values?.Container}
                  feedbackValid=''
                  feedbackInvalid='Please Enter Booking Number.'
                  required
                />
                <CFormLabel htmlFor='Vname' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Vessel Name <span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormSelect
                  id='VesselName'
                  datatestid='VesselName'
                  onChange={props.handleChange('VesselName')}
                  value={props?.values?.VesselName}
                  feedbackValid=''
                  feedbackInvalid='Please choose Vessel Name'
                  required
                >
                  <option selected='' disabled='' value=''>
                    Choose...
                  </option>
                  {VesselName.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CFormSelect>
                <CFormLabel htmlFor='line' className='col-sm-4 col-form-label'>
                  Freight Line<span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormInput
                  type='text'
                  id='FreightLine'
                  placeholder='Enter Freight Line'
                  onChange={props.handleChange('FreightLine')}
                  value={props?.values?.FreightLine}
                  feedbackValid=''
                  feedbackInvalid='Please Enter Freight Line.'
                  required
                />
                <CFormLabel htmlFor='departure' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Departure Port <span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormSelect
                  id='DeparturePort'
                  datatestid='DeparturePort'
                  onChange={props.handleChange('DeparturePort')}
                  value={props?.values?.DeparturePort}
                  feedbackValid=''
                  feedbackInvalid='Please choose Departure Port'
                  required
                >
                  <option selected='' disabled='' value=''>
                    Choose...
                  </option>
                  {DeparturePort.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CFormSelect>
                <CFormLabel htmlFor='Awarehouse' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Arrival Warehouse <span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormSelect
                  id='ArrivalWarehouse'
                  datatestid='ArrivalWarehouse'
                  onChange={props.handleChange('ArrivalWarehouse')}
                  value={props?.values?.ArrivalWarehouse}
                  feedbackValid=''
                  feedbackInvalid='Please choose ArrivalWarehouse'
                  required
                >
                  <option selected='' disabled='' value=''>
                    Choose...
                  </option>
                  {arrivalWarehouse?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                  {/* {props.warehouseName?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                    
                  ))} */}
                </CFormSelect>
                <CFormLabel htmlFor='Aport' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Arrival Port <span style={{ color: 'red' }}>*</span>
                </CFormLabel>
                <CFormSelect
                  id='ArrivalPort'
                  datatestid='ArrivalPort'
                  onChange={props.handleChange('ArrivalPort')}
                  value={props?.values?.ArrivalPort}
                  feedbackValid=''
                  feedbackInvalid='Please choose Arrival Port'
                  required
                >
                  <option selected='' disabled='' value=''>
                    Choose...
                  </option>
                  {ArrivalPort?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>

              <CCol sm={6} lg={6}>
                <CFormLabel htmlFor='sdate' className='col-sm-4 col-form-label'>
                  Start Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='StartDate'
                  onChange={props.handleChange('StartDate')}
                  value={props?.values?.StartDate}
                  className={props?.values?.StartDate ? 'is-valid' : ''}
                  min={new Date().toJSON().slice(0, 10)}
                  feedbackValid=''
                  feedbackInvalid='Please select Start Date'
                  required
                />

                <CFormLabel htmlFor='ldate' className='col-sm-4 col-form-label'>
                  Load Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='loadDate'
                  placeholder='Input the Load Date'
                  onChange={props.handleChange('loadDate')}
                  value={props?.values?.loadDate}
                  min={props?.values?.StartDate}
                  feedbackValid=''
                  feedbackInvalid='Please select Load Date'
                  required
                />

                <CFormLabel htmlFor='cdate' className='col-sm-4 col-form-label'>
                  Cut-Off Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='CutOffDate'
                  onChange={props.handleChange('CutOffDate')}
                  value={props?.values?.CutOffDate}
                  // min={props?.values?.StartDate}
                  // feedbackValid=''
                  // feedbackInvalid='Please select Cut-Off Date'
                  // required
                />

                <CFormLabel htmlFor='ddate' className='col-sm-4 col-form-label'>
                  Departure Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='DepartureDate'
                  onChange={props.handleChange('DepartureDate')}
                  value={props?.values?.DepartureDate}
                  // min={props?.values?.StartDate}
                  // feedbackValid=''
                  // feedbackInvalid='Please select Departure Date'
                  // required
                />

                <CFormLabel htmlFor='adate' className='col-sm-4 col-form-label'>
                  Arrival Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='ArrivalDate'
                  placeholder='Input the Arrival Date'
                  onChange={props.handleChange('ArrivalDate')}
                  value={props?.values?.ArrivalDate}
                  // min={props?.values?.StartDate}
                  // feedbackValid=''
                  // feedbackInvalid='Please select Arrival Date'
                  // required
                />

                <CFormLabel htmlFor='portdate' className='col-sm-4 col-form-label'>
                  {'Container\u00a0Return\u00a0to\u00a0Port\u00a0Date'}
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='PortDate'
                  placeholder='Input the Container Return to Port Date'
                  onChange={props.handleChange('containerReturntoPortDate')}
                  value={
                    props?.values?.containerReturntoPortDate
                      ? props?.values?.containerReturntoPortDate
                      : props?.values?.ContainerReturntoPortDate
                  }
                  // min={props?.values?.StartDate}
                  // feedbackValid=''
                  // feedbackInvalid='Please select Container Return to Port Date'
                  // required
                />

                <CFormLabel htmlFor='deliverydate' className='col-sm-4 col-form-label'>
                  Delivery Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='DeliveryDate'
                  placeholder='Input the Delivery Date'
                  onChange={props.handleChange('DeliveryDate')}
                  value={props?.values?.DeliveryDate}
                  // min={props?.values?.StartDate}
                  feedbackValid=''
                  feedbackInvalid='Please select Delivery Date'
                  required
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CForm>
    </Fragment>
  )
}

export default ProjectHeader

ProjectHeader.propTypes = {
  handleDropdownChange: PropTypes.func,
  productCategory: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  productValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subCategory: PropTypes.array,
  subCategoryValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleChange: PropTypes.func,
  handleSwitch: PropTypes.func,
  handleSubmit: PropTypes.func,
  validated: PropTypes.bool,
  values: PropTypes.object,
  isCShop: PropTypes.bool,
  isValidated: PropTypes.bool,
  arrivalWarehouse: PropTypes.array
}
