import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { BsThreeDotsVertical } from 'react-icons/bs'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Skeleton } from '@mui/material'
import { UserContext } from 'context/AuthContext/UserContext'
export default function Actions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [validated, setValidated] = React.useState(true)
  const contextObj = React.useContext(UserContext)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    setLoading(false)
  }, [props.poDetails])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEdit = () => {
    props.setContent({ proposal: true })
    props.setModal(true)
    props.setPId(props.productData.id)
    props.setSku(props.productData.sku)
    handleClose()
  }

  const handleHistory = () => {
    props.setContent({ history: true })
    props.setModal(true)
    props.setPId(props.productData.id)
    props.setSku(props.productData.sku)
    handleClose()
  }

  const handleValidate = () => {
    props.setContent({ validation: true })
    props.setSku(props.productData.sku)
    if (!validated) {
      setValidated(true)
    }
    props.setModal(true)
    props.setPId(props.productData.id)
    handleClose()
  }
  const CancelPo = () => {
    props.setContent({ cancel: true })
    props.setSku(props.productData.sku)
    if (!validated) {
      setValidated(true)
    }
    props.setModal(true)
    props.setPId(props.productData.id)
    handleClose()
  }

  useEffect(() => {
    const products = props.product
    const allProductsValidated = products.every((product) => product.validatedQty?.isValidated === true)
    setValidated(allProductsValidated)
    setLoading(false)
  }, [props.product])

  return (
    <>
      {loading ? (
        <Skeleton animation='wave' variant='rectangle' width='100%' height='1px' />
      ) : (
        <>
          {contextObj.userData.user.role >= 4 ? (<div>
            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <BsThreeDotsVertical color='#6495ED' fontSize={22} />
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              {(props.poDetails.status === 'Quotation' || props.poDetails.status === 'Request For Change' || props.poDetails.status === 'Proposal') && (
                <MenuItem className='text-warning'  onClick={handleEdit}>Proposal</MenuItem>
              )}

              {(props.poDetails.status === 'Quotation' || props.poDetails.status === 'Request For Change' || props.poDetails.status === 'Proposal') &&
                contextObj.userData.user.pomUserType === 1 && <MenuItem className='text-success' onClick={handleValidate}>Validate</MenuItem>}
              <MenuItem onClick={handleHistory}>History</MenuItem>
              {(props.poDetails.status === 'Quotation' ||
                props.poDetails.status === 'Proposal' ||
                props.poDetails.status === 'Request For Change' ||
                props.poDetails.status === 'Confirmed') && <MenuItem className='text-danger' onClick={CancelPo}>Cancel</MenuItem>}
            </Menu>
          </div>) : ("")}
        </>
      )}
    </>
  )
}

Actions.propTypes = {
  setModal: PropTypes.func,
  setPId: PropTypes.func,
  setSku: PropTypes.func,
  productData: PropTypes.object,
  poDetails: PropTypes.object,
  product: PropTypes.object,
  setIsHistory: PropTypes.bool,
  validate: PropTypes.func,
  setContent: PropTypes.func
}
