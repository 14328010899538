import { useContext, useState, useEffect } from 'react'
import authService from 'shared/services/auth.service'
import { UserContext } from 'context/AuthContext/UserContext'
import productService from 'shared/services/products.service'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import moment from 'moment'
import { FilterUniqueIds, manipulateString, sortAlphabeticOrder } from 'shared/util/helper'

export const usePoHooks = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const param = useParams()
  const contextObj = useContext(UserContext)
  const { loading, setLoading, newParam } = useContext(UserContext)
  const [values, setValues] = useState({
    UserId: '',
    CompanyId: '',
    Products: [],
    PoDate:new Date().toJSON().slice(0, 10),
    shipVia:"Vessel",
    shipTo:"Turkey Warehouses",
    PoDueDate:moment(new Date()).add(1, 'months').toJSON().slice(0, 10)
  })
  const [validated, setValidated] = useState(false)
  const [success, setSuccess] = useState(false)
  const [product, setProducts] = useState([])
  const [vendors, setVendors] = useState([])
  const [skuValue, setSkuValue] = useState([])
  const [warehouseName, setWarehouseName] = useState([])
  const [vendorProducts, setVendorProducts] = useState([])
  const [vendorsAllProducts, setVendorsAllProducts] = useState([])
  const [error, setError] = useState('')
  const { getAllProductOnVendorId } = productService

  useQuery(
    ['vendors', contextObj.userData.user.companyId],
    async () => await authService.getVendors(contextObj.userData.user.companyId),
    {
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        const FilterVendors = FilterUniqueIds(response.data, contextObj?.userData.user?.companyId)
        setVendors(FilterVendors)
      },
      onError: (error) => {
        console.log(error, 'vendorserrror')
      },
      enabled: contextObj.userData.user.companyId ? true : false
    }
  )

  useQuery(
    ['getWarehouse'],
    async () => {
      const response = await purchaseOrderService.getWarehouseData()
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        response = sortAlphabeticOrder(response, 'warehouse')
        const sortRes = response.map((item) => item.warehouse)
        setWarehouseName(sortRes)
      },
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )

  useQuery(
    ['getLastPO'],
    async () => {
      const response = await purchaseOrderService.getLastPurchaseOrder(contextObj.userData.user.companyId)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        const LastPoNumber = await manipulateString(response.poNumber)
        setValues((prevValues) => ({
          ...prevValues,
          PoNumber: LastPoNumber
        }))
      },
      enabled: !param.poNumber,
      onError: (error) => {
        setError(error.response?.data?.message || 'Some error occurred')
      }
    }
  )

  const {} = useQuery(
    ['formData', param.poNumber],
    async () => {
      if (param.poNumber) {
        const response = await purchaseOrderService.getSinglePurchaseOrder(param.poNumber)

        if (response) {
          const podate = response.data.poDate ? moment(response.data.poDate).format('YYYY-MM-DD') : null
          const poloaddate = response.data.poLoadDate ? moment(response.data.poLoadDate).format('YYYY-MM-DD') : null
          const poduedate = response.data.poDueDate ? moment(response.data.poDueDate).format('YYYY-MM-DD') : null
          const updatedResponse = {
            PoNumber: response?.data?.poNumber,
            PoDate: podate,
            PoLoadDate: poloaddate,
            PoDueDate: poduedate,
            ...Object.keys(response.data)
              .filter((key) => !['poNumber', 'poDate', 'poLoadDate', 'poDueDate'].includes(key))
              .reduce((obj, key) => {
                obj[key] = response.data[key]
                return obj
              }, {})
          }
          setValues(updatedResponse)
          await getPoProducts(response?.data)
          return updatedResponse
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: param.poNumber ? true : false,
      onSuccess: () => {},
      onError: (error) => {
        console.log(error)
        setError('Seems like our server is down')
      }
    }
  )
  const { mutate: getPoProducts } = useMutation(
    async (data, productCompany) => {
      const productIds = data?.products?.map((item) => item.productId)
      const updatedProducts = data?.products?.map((product) => {
        const { expectedQty, productId } = product
        const { supplierName, supplier } = data
        return { expectedQty, productId, supplierName, supplier }
      })
      if (productIds) {
        const response = await purchaseOrderService.getProductsOnIds(productIds)
        addPoProduct(response.data, updatedProducts, productCompany)
      }
    },
    {
      onError: (error) => {
        console.error(error)
      }
    }
  )

  const addPoProduct = async (data, updatedProducts, productCompany) => {
    const newProducts = data.map((product) => {
      const quantity = updatedProducts.find((q) => q.productId === product.id)
      return {
        poProduct: product,
        qty: quantity ? quantity.expectedQty : null,
        supplier: quantity ? quantity.supplier : null,
        supplierName: quantity ? quantity.supplierName : null
      }
    })
    setSkuValue(newProducts)
    const filteredIds = newProducts.map((sku) => sku.poProduct.id)
    const updatedProductssss = productCompany?.filter((product) => !filteredIds.includes(product.id))
    setVendorProducts(updatedProductssss)
  }

  const handleChange = (value) => async (event) => {
    if (value === 'supplier' && event.target.value !== '') {
      let supplierData = JSON.parse(event.target.value)

      const selectedOption = event.target.options[event.target.selectedIndex]
      const email = selectedOption.dataset.email
      const fName = selectedOption.dataset.fname
      setValues((prevValues) => ({
        ...prevValues,
        supplier: supplierData.value,
        supplierName: supplierData.key,
        email: email,
        fname: fName,
        supplierCompany: supplierData.supplierCompany
      }))
      setSkuValue([])
      await getVendorProducts(supplierData.supplierCompany)
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [value]: event.target.value
      }))
    }
  }

  const { mutate: getVendorProducts } = useMutation(
    async (prodData) => {
      const isObject = typeof prodData === 'object'
      const supplierId = isObject ? prodData.supplier : prodData
      const response = await getAllProductOnVendorId(supplierId, 500, 0, newParam.get('po_sku'))
      return response.data?.productResponses
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (data, prodData) => {
        setVendorProducts(data)
        setVendorsAllProducts(data)
        await getPoProducts(prodData, data)
      },
      onError: (error) => {
        console.error(error)
        // Handle error if needed
      }
    }
  )

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      getVendorProducts(values.supplierCompany)
    } else if (values.supplierCompany) {
      getVendorProducts(values.supplierCompany)
    }
  }, [values.supplierCompany, newParam.get('po_sku')])

  const handleAddProduct = (value) => (event) => {
    setProducts({ ...product, [value]: event.target.value })
  }

  const handlePoSubmit = async (event, isPublished = false) => {
    setValidated(true)
    if (event) {
      const form = event?.currentTarget
      if (form?.checkValidity() === false) {
        event?.preventDefault()
        event?.stopPropagation()
      }
    }
    const products = skuValue?.map((item) => {
      return {
        expectedQty: item.qty,
        productId: item.poProduct.id,
        createdBy: item?.createdBy
      }
    })
    if (!values?.PoNumber || !values?.supplier || !values?.PoDate) {
      return
    }
    let ProductValues = {
      ...values,
      Products: products,
      UserId: contextObj.userData.user.id,
      UserEmail: contextObj?.userData?.user?.email,
      CompanyId: contextObj.userData.user.companyId,
      status: 'Quotation',
      isDispatch: false,
      isPublished
    }

    if (ProductValues?.Products?.length != 0) {
      setLoading(true)
      await poSubmitUseMutation(ProductValues)
      setValidated(false)
    }
    setValues({
      UserId: '',
      CompanyId: '',
      Products: [],
      isDispatch: false
    })
  }

  const { mutate: poSubmitUseMutation } = useMutation(
    async (values) => await purchaseOrderService.addPurchaseOrder(values),
    {
      onSuccess: (data) => {
        setError('')
        setSuccess(true)
        setLoading(false)
        setTimeout(() => {
          navigate(`/purchaseOrder/${data?.data?.poNumber}`)
        }, 1500)
      },
      onError: (err) => {
        setLoading(false)
        setSuccess(false)
        setError(err)
        console.log(err)
      }
    }
  )

  const EditPurchaseOrder = async (event, isPublished = false) => {
    if (values?.isPublished === true) {
      await handlePoPublish()
    }
    setValidated(true)
    let arr = []
    if (event) {
      const form = event?.currentTarget
      if (form?.checkValidity() === false) {
        event?.preventDefault()
        event?.stopPropagation()
      }
    }
    vendors.map((item) => {
      if (item?.id == values?.supplier) {
        arr.push(item)
      }
    })
    const products = skuValue.map((item) => {
      return {
        expectedQty: item.qty,
        productId: item.poProduct.id,
        createdBy: item?.createdBy
      }
    })
    let ProductValues = {
      ...values,
      Products: products,
      companyName: arr[0]?.companyName,
      UserEmail: contextObj?.userData?.user?.email,
      email: arr[0]?.email,
      fname: arr[0]?.fName,
      poNumber: values?.PoNumber,
      isPublished
    }
    if (ProductValues.Products.length != 0) {
      setLoading(true)
      await editPurchaseOrderUseMutation(ProductValues)
      setValidated(false)
    }
    setValues({
      UserId: '',
      CompanyId: '',
      Products: []
    })
  }

  const { mutate: editPurchaseOrderUseMutation } = useMutation(
    async (ProductValues) => await purchaseOrderService.updatePurchaseOrder(ProductValues),
    {
      onSuccess: async (_, variables) => {
        if (contextObj.userData.user?.pomUserType === 2) {
          let payload = {
            status: 'Request For Change'
          }
          await purchaseOrderService.updatePurchaseOrderStatus(variables?._id, payload)
        }
        setError('')
        setSuccess(true)
        setLoading(false)
        setTimeout(() => {
          navigate(`/purchaseOrder/${param.poNumber}`)
        }, 1500)
      },
      onError: (err) => {
        setLoading(false)
        console.error(err || 'Something went Wrong')
        setError('Something went Wrong')
      }
    }
  )

  return {
    values,
    loading,
    handleChange,
    handleAddProduct,
    product,
    handlePoSubmit,
    validated,
    vendors,
    success,
    skuValue,
    setSkuValue,
    vendorProducts,
    error,
    EditPurchaseOrder,
    setVendorProducts,
    vendorsAllProducts,
    setVendorsAllProducts,
    warehouseName
  }
}
